<template>
  <div
    class="theme-vanderbuild auth background-defaults min-h-screen min-h-screen-ios-fix w-full grid place-items-center p-7.5"
  >
    <div class="max-w-md">
      <div class="flex justify-center mb-8 space-x-4">
        <!-- bg-opacity-0 hover:bg-transparent is a fix for overriding bg-brand-primary that is specified in forms/index.js  -->
        <FormulateInput
          v-for="lang in langOptions"
          :key="lang.value"
          type="button"
          :input-class="[
            lang.value === currentLocale
              ? 'bg-opacity-0 hover:bg-transparent border-2 border-white'
              : 'bg-brand-primary'
          ]"
          @click="setUserLocale({ lang: lang.value })"
        >
          {{ lang.title }}
        </FormulateInput>
      </div>
      <div class="rounded w-full bg-white shadow-xl">
        <div class="flex flex-col py-7.5 px-5 md:px-7.5">
          <img
            src="@/assets/logo_dewaelevastgoedgroep.png"
            alt="Dewaele Vastgoed Groep"
            class="w-22 mx-auto"
          />
          <transition name="fade" mode="out-in">
            <router-view :key="$route.fullPath" class="w-full" />
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'AuthLayout',
  data() {
    return {
      langOptions: [
        {
          title: 'NL',
          value: 'nl'
        },
        {
          title: 'FR',
          value: 'fr'
        },
        {
          title: 'EN',
          value: 'en'
        }
      ]
    };
  },
  computed: {
    currentLocale() {
      return this.$i18n.locale;
    }
  },
  methods: {
    ...mapActions('profile', ['setUserLocale'])
  }
};
</script>

<style scoped>
.auth {
  background-image: url('../assets/background_auth.png');
}
</style>
